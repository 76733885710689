import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default defineNuxtPlugin((nuxtApp) => {
    // @ts-ignore
    if (typeof window !== 'undefined') {
    
        CookieConsent.run({

            // root: 'body',
            // autoShow: true,
            // disablePageInteraction: true,
            // hideFromBots: true,
            // mode: 'opt-in',
            // revision: 0,
        
            cookie: {
                name: 'cc_cookie',
                // domain: location.hostname,
                // path: '/',
                // sameSite: "Lax",
                expiresAfterDays: 15,
            },
        
            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    equalWeightButtons: true,
                    flipButtons: true
                },
                preferencesModal: {
                    layout: 'box',
                    equalWeightButtons: true,
                    flipButtons: false
                }
            },
        
            onFirstConsent: ({cookie}) => {
                // console.log('onFirstConsent fired',cookie);
            },
        
            onConsent: ({cookie}) => {
                // console.log('onConsent fired!', cookie)
            },
        
            onChange: ({changedCategories, changedServices}) => {
                // console.log('onChange fired!', changedCategories, changedServices);
            },
        
            onModalReady: ({modalName}) => {
                // console.log('ready:', modalName);
            },
        
            onModalShow: ({modalName}) => {
                // console.log('visible:', modalName);
            },
        
            onModalHide: ({modalName}) => {
                // console.log('hidden:', modalName);
            },
        
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {
                    autoClear: {
                        cookies: [
                            {
                                name: /^_ga/,   // regex: match all cookies starting with '_ga'
                            },
                            {
                                name: '_gid',   // string: exact cookie name
                            }
                        ]
                    },
        
                    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                    services: {
                        ga: {
                            label: 'Google Analytics',
                            onAccept: () => {},
                            onReject: () => {}
                        }
                    }
                },
                ads: {}
            },
        
            language: {
                default: 'es',
                translations: {
                    es: {
                        consentModal: {
                            title: 'Aviso de privacidad',
                            description: 'Con su consentimiento, usamos cookies o tecnologías similares para almacenar, acceder y procesar datos personales como su visita en este sitio web. Puede retirar su consentimiento u oponerse al procesamiento tratamiento de datos basado en intereses legítimos en cualquier momento.',
                            acceptAllBtn: 'Aceptar todo',
                            acceptNecessaryBtn: 'Rechazar todo',
                            showPreferencesBtn: 'Configuarción de cookies',
                            // closeIconLabel: 'Reject all and close modal',
                            footer: `
                                <a href="/politica-de-privacidad" target="_blank">Política de privacidad</a>
                                <a href="/politica-de-cookies" target="_blank">Política de cookies</a>
                                <a href="/aviso-legal" target="_blank">Aviso legal</a>
                            `,
                        },
                        preferencesModal: {
                            title: 'Configurar cookies',
                            acceptAllBtn: 'Aceptar todo',
                            acceptNecessaryBtn: 'Rechazar todo',
                            savePreferencesBtn: 'Aceptar la actual selección',
                            closeIconLabel: 'Cerrar',
                            serviceCounterLabel: 'Servicio|Servicios',
                            sections: [
                                {
                                    title: 'Sus opciones de privacidad',
                                    description: `En este panel usted puede expresar algunas preferencias relacionadas con el procesamiento de su información personal. Puede revisar y cambiar las opciones expresadas en cualquier momento haciendo resurgir este panel a través del enlace proporcionado. Para negar su consentimiento a las actividades de procesamiento específicas que se describen a continuación, desactive los controles o use el botón "Rechazar todo" y confirme que desea guardar sus opciones.`,
                                },
                                {
                                    title: 'Estrictamente necesarias',
                                    description: 'Estas cookies son esenciales para el correcto funcionamiento de la web (control de la sesión del usuario), no pueden ser deshabilitadas.',
        
                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Analítica',
                                    description: 'Estas cookies nos ayuda a medir cómo interactúan los usuarios con el contenido del sitio. Toda la información es ánonima y no puede ser usada para identificarte.',
                                    linkedCategory: 'analytics',
                                    cookieTable: {
                                        caption: 'Cookie table',
                                        headers: {
                                            name: 'Cookie',
                                            domain: 'Domain',
                                            desc: 'Description'
                                        },
                                        body: [
                                            {
                                                name: '_ga',
                                                domain: location.hostname,
                                                desc: 'Se usa para distinguir a los usuarios.',
                                            },
                                            {
                                                name: '_gid',
                                                domain: location.hostname,
                                                desc: 'Se usa para distinguir a los usuarios.',
                                            }
                                        ]
                                    }
                                },
                                {
                                    title: 'Más información',
                                    description: 'Para cualquier duda o consulta sobre nuestra política de privacidad y de cookies, por favor <a href="/contacto">contacta con nosotros</a>'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }
});