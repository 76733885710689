export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"manifest","href":"/manifest.json"},{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"preconnect","href":"https://fonts.googleapis.com","as":"font"},{"rel":"preconnect","href":"https://fonts.gstatic.com","as":"font"},{"href":"https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap","as":"font"}],"style":[],"script":[{"src":"/js/components/tpr-bookmark.js","body":true,"type":"module"},{"src":"/js/components/tpr-youtube.js","body":true,"type":"module"},{"src":"/js/components/tpr-twitter.js","body":true,"type":"module"},{"src":"/js/init.js","body":true},{"src":"/js/index.js","body":true,"async":true},{"src":"/js/global.js","body":true},{"src":"/js/notyf.min.js","body":true},{"src":"/js/tingle.js","body":true},{"src":"//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js","body":true},{"src":"/js/magnific-popup.min.js","body":true}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null